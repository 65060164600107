import classnames from 'classnames';
import { Svg, Icon, Button } from '@webfx/core-web';

import { useActiveSite, useRouter } from '@webfx/web-hooks';
import LaptopFunnel from '@webfx/marketingcloud-web/src/assets/svgs/laptop-funnel.svg';

import styles from './IntegrationSetupModal.module.css';

const IntegrationSetupModal = ({ setShowModal }) => {
  const site = useActiveSite();
  const { url } = useRouter();

  return (
    <div className={styles.modalContainer}>
      <div
        className={classnames(styles.modal, 'bg-white shadow rounded d-flex flex-column')}
        data-fx-name="integrationEmailModal"
      >
        <div
          className="ml-auto mt-3 mr-3 cursor-pointer"
          onClick={() => {
            setShowModal(false);
          }}
          data-fx-name="closeModalButton"
        >
          <Icon className="font-28 text-gray-550 text-hover-gray-700">close</Icon>
        </div>
        <div className="d-flex flex-grow-1 mb-2">
          <div className="d-flex flex-column flex-grow-1">
            <div className="row mx-0">
              <div className="d-flex flex-column pl-5 col-7 p-0">
                <p className={classnames(styles.modalHeading, 'bold m-0 mb-2')}>
                  Keys to Revenue Growth
                </p>
                <div
                  className={classnames(
                    'text-gray-600 mt-1 d-flex align-items-center',
                    styles.modalSubheading
                  )}
                >
                  <Icon className="text-green-05 font-24 mr-1">check</Icon> Measure demand
                  generation with traffic KPIs
                </div>
                <div
                  className={classnames(
                    'text-gray-600 mt-2 d-flex align-items-center',
                    styles.modalSubheading
                  )}
                >
                  <Icon className="text-green-05 font-24 mr-1">check</Icon> Measure lead generation
                  with form and call KPIs
                </div>
                <div
                  className={classnames(
                    'text-gray-600 mt-2 d-flex align-items-center',
                    styles.modalSubheading
                  )}
                >
                  <Icon className="text-red-05 font-24 mr-1">close</Icon> Measure revenue generation
                  with sales + CRM data
                </div>
                <div
                  className={classnames(
                    'text-red-05 mt-2 d-flex align-items-center p-2 text-nowrap rounded',
                    styles.alert
                  )}
                >
                  <Icon outlined className="text-red-03 font-24 mr-1">
                    warning
                  </Icon>
                  Your profile has <span className="bold mx-1">0 closed won deals</span> for the
                  past
                  <span className="bold ml-1">90 days</span>
                </div>
              </div>
              <div className="d-flex ml-auto col-5">
                <Svg className={styles.modalSvg} src={LaptopFunnel} />
              </div>
            </div>
            <div className={classnames('text-gray-600 mt-2 col-12 px-5', styles.modalContent)}>
              Connect
              <span className="bold"> every digital touchpoint to revenue outcomes</span> when you
              <span className="bold"> import CRM data</span> to know what digital marketing
              <span className="bold"> efforts drive measurable revenue outcomes.</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-grow-1 px-5 mb-3 flex-column">
          <div className={classnames('text-blue-vivid-400 bold mb-3', styles.modalHeading)}>
            Options for Importing CRM Data:
          </div>
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column flex-grow-1">
              <div className="row">
                <div className={classnames('text-gray-600 mb-2 col-6', styles.modalContent)}>
                  <span className="bold">Best: </span>
                  Automatic daily sync set-up and monitored by our DataTech Solutions team
                </div>
                <div className={classnames('text-gray-600 mb-2 col-6', styles.modalContent)}>
                  <span className="bold">Better: </span>
                  Monthly data upload
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="font-14 text-gray-600 d-flex align-items-center">
                    <Icon outlined className="text-blue-vivid-100 font-24 mr-1">
                      schedule
                    </Icon>
                    <span className="font-italic">Upfront time needed:</span>
                  </div>
                  <div
                    className={classnames(
                      'font-14 text-gray-600 mb-2 line-height-1-2',
                      styles.subLabel
                    )}
                  >
                    <span className="bold">1-3 hours </span>
                    depending on the CRM
                  </div>
                </div>
                <div className="col">
                  <div className="font-14 text-gray-600 d-flex align-items-center">
                    <Icon outlined className="text-blue-vivid-100 font-24 mr-1">
                      schedule
                    </Icon>
                    <span className="font-italic">Upfront time needed:</span>
                  </div>
                  <div
                    className={classnames(
                      'font-14 text-gray-600 mb-2 line-height-1-2',
                      styles.subLabel
                    )}
                  >
                    <span className="bold">~10 minutes </span>
                    to determine what you&apos;d like to get imported
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="font-14 text-gray-600 d-flex align-items-center">
                    <Icon outlined className="text-blue-vivid-100 font-24 mr-1">
                      update
                    </Icon>
                    <span className="font-italic">Ongoing time needed:</span>
                  </div>
                  <div
                    className={classnames('font-14 text-gray-600 line-height-1-2', styles.subLabel)}
                  >
                    <span className="bold">Minimal</span>
                  </div>
                </div>

                <div className="col">
                  <div className="font-14 text-gray-600 d-flex align-items-center">
                    <Icon outlined className="text-blue-vivid-100 font-24 mr-1">
                      update
                    </Icon>
                    <span className="font-italic">Ongoing time needed:</span>
                  </div>
                  <div
                    className={classnames('font-14 text-gray-600 line-height-1-2', styles.subLabel)}
                  >
                    <span className="bold">~5 minutes </span>
                    each month or
                    <span className="bold ml-1">0</span>, if our team can access the CRM to export
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <Button
                    className={styles.button}
                    icon="arrow_forward"
                    iconTrailing
                    iconPosition="right"
                    to={url({ route: 'mcfxIntegrationsCRMpipelines', siteId: site.siteId })}
                  >
                    View capabilities based on your plan and CRM
                  </Button>
                </div>
                <div className="col">
                  <Button
                    className={classnames(styles.button, styles.outline)}
                    icon="arrow_forward"
                    iconTrailing
                    iconPosition="right"
                    to={url({ route: 'mcfxCustomer360Upload', siteId: site.siteId })}
                  >
                    Check out the data uploader
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames(
            styles.modalFooter,
            'd-flex flex-column flex-grow-1 align-items-center w-100'
          )}
        >
          <p className="font-20 text-white bold">
            Stop missing out on key KPIs and a{' '}
            <span className="text-green-03">20% performance lift</span>
          </p>
          <p className="font-14 text-white font-italic text-center">
            How does CRM data drive a 20% performance lift? With integrations to Ad Platforms like
            Google and Meta, which allows them to see who is best suited for your business, so they
            can optimize their algorithms for your campaign.
          </p>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default IntegrationSetupModal;
