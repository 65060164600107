import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import useDealChannelStats from '@webfx/marketingcloud-web/src/hooks/analytics/useDealChannelStats';
import useDeals from '@webfx/marketingcloud-web/src/hooks/useDeals';
import { useActiveSite } from '@webfx/web-hooks';

import IntegrationSetupModal from './IntegrationSetupModal';

const useIntegrationSetupModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { crmIntegrated, isFetched: isCrmIntegratedFetched } = useActiveSite({
    $join: ['crmIntegrated'],
  });
  const startDate = DateTime.local().minus({ days: 90 }).toISODate();
  const endDate = DateTime.local().toISODate();
  const { data: wonDeals, isFetched } = useDealChannelStats({
    startDate,
    endDate,
    dateColumn: 'close_at',
    category: 'closed won',
  });

  const { data: crmDeals, isFetched: isCrmDealsFetched } = useDeals({
    closeAt: {
      $gte: startDate,
      $lte: endDate,
    },
    category: 'closed won',
    isIngestionDeal: false,
  });

  useEffect(() => {
    if (!isFetched || !isCrmIntegratedFetched || !isCrmDealsFetched) {
      return;
    }

    if (crmIntegrated?.isIntegrated && crmDeals?.length > 0) {
      setShowModal(true);
      return;
    }

    if (wonDeals.totalCount === 0) {
      setShowModal(true);
    }
  }, [isFetched, isCrmDealsFetched, isCrmIntegratedFetched]);

  return {
    showModal,
    setShowModal,
    IntegrationSetupModal,
  };
};

export default useIntegrationSetupModal;
