'use strict';

const handleUpload = ({
  file,
  apiUpload,
  uploadsMutator,
  handleSetUploadedFile,
  setProjectIdForUpload,
  setFile,
  uploadFile,
  formObject,
  index,
  invalidateQuery,
}) => {
  const request = uploadFile({ file, apiUpload });
  request.then((response) => {
    const { data } = response;

    handleSetUploadedFile({
      file: {
        ...file,
        ...data,
      },
      index,
    });
    // update file with project id
    setProjectIdForUpload({ mutator: uploadsMutator, setFile })({
      uploadId: data.uploadId,
      formObject,
      file,
    });
    invalidateQuery(formObject);
  });

  request.catch((_error) => {
    const failedUpload = {
      ...file,
      name: file.name,
      type: file.type,
      fileData: file.fileData,
    };
    failedUpload.status = 'failed';

    setFile(failedUpload);
  });
};

export default handleUpload;
